.body {
  margin: 20px 0;
}
.pages-container {
  background-color: white;

  height: 100%;

  .page-row {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    border-bottom: 1px solid lightgrey;

    &.header-row {
      font-weight: bold;
      width: 100%;
      // border-bottom: 3px solid lightgrey;
    }

    .sort-handle {
      width: 30px;
    }

    .image-col {
      width: 100px;
    }

    .name {
      width: 250px;
    }

    .url {
      flex-grow: 1;
      width: 50%;
    }

    .action-col {
      width: 80px;
      text-align: center;
    }
  }
}