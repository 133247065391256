.card {
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0,0,0, 0.16);
  width: 100%;

  // margin-bottom: 20px;

}

.card-header {
  padding: 0 20px;
  border-bottom: 1px solid #E9E9E9;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    margin: 15px 15px 15px 0;
    font-size: 24px;
    font-weight: bold;
  }

  .sub-title {
    font-size: 18px;
  }

  .button {
    margin: 15px 0;
  }

  .actions {
    display: flex;
    align-items: center;
    & > * {
      margin: 0 5px;
    }
  }

  .filter-row {
    display: flex;
    justify-content: space-between;

    & > * {
      margin: 5px;
    }
  }
}

.card-body {
  padding: 15px;

  .actions {
    .button {
      margin: 0 5px;
    }
  }
}

.card-modal {

  .card {
    height: 100%;
  }
}


.card-row {
  display: flex;
  justify-content: space-between;

  .card-row-100 {
    width: 100%;
  }

  .card-row-50 {
    width: 49%;
    
  }

  .card-row-30 {
    width: 30%;
  }
}