.image-wrapper {
  width: 100%;
  height: 200px;

  &.icon {
    height: 30px;
    width: 30px;

    .image {
      border: 1px solid lightgrey;
      border-radius: 10px;
      background-size: cover;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}

.user-icon {
  background-image: url('../assets/profile_icon.png');
}