.nav-container {
  width: 100%;

  &.extend {

    .nav-item {
      width: 200px;
    }

    .nav-link {

      .icon {
        width: 50px;
      }

      .name {
        display: flex;
      }
    }
  }

  .nav-item {
    width: 50px;
    padding: 5px;

    &.child {
      .nav-link {
        margin-left: 20px;
        padding: 10px 0;
        font-size: 14px;
      }
    }
  }


  .nav-link {
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    color: #888888;

    display: flex;
    align-items: center;


    &:hover {
      color: #407BFF;
    }

    .name {
      display: none;
    }
  }

  .active {
    background-color: #407BFF;
    color: #FFFFFF;

    &:hover {
      color: #FFFFFF;
    }
  }

  .child-active {
    color: #407BFF;
  }

  a {
    display: block;
  }
  
}

.menu-icon-wrapper {
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.menu-icon {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.menu-dashboard {
  background-image: url('../../assets/menu_dashboard.png');
}

.menu-categories {
  background-image: url('../../assets/menu_categories.png');
}

.menu-chat-group {
  background-image: url('../../assets/menu_chat_group.png');
}

.menu-events {
  background-image: url('../../assets/menu_events.png');
}

.menu-poll {
  background-image: url('../../assets/menu_poll.png');
}

.menu-posts {
  background-image: url('../../assets/menu_posts.png');
}

.menu-post-templates {
  background-image: url('../../assets/menu_post_templates.png');
}

.menu-notifications {
  background-image: url('../../assets/menu_notifications.png');
}

.menu-promotions {
  background-image: url('../../assets/menu_promotions.png');
}

.menu-settings {
  background-image: url('../../assets/menu_settings.png');
}

.menu-surveys {
  background-image: url('../../assets/menu_surveys.png');
}

.menu-urgent-news {
  background-image: url('../../assets/menu_urgent_news.png');
}

.menu-users {
  background-image: url('../../assets/menu_users.png');
}

.active {
  .menu-dashboard {
    background-image: url('../../assets/menu_dashboard_active.png');
  }

  .menu-categories {
    background-image: url('../../assets/menu_categories_active.png');
  }

  .menu-chat-group {
    background-image: url('../../assets/menu_chat_group_active.png');
  }

  .menu-events {
    background-image: url('../../assets/menu_events_active.png');
  }

  .menu-poll {
    background-image: url('../../assets/menu_poll_active.png');
  }

  .menu-posts {
    background-image: url('../../assets/menu_posts_active.png');
  }

  .menu-post-templates {
    background-image: url('../../assets/menu_post_templates_active.png');
  }

  .menu-notifications {
    background-image: url('../../assets/menu_notifications_active.png');
  }

  .menu-promotions {
    background-image: url('../../assets/menu_promotions_active.png');
  }

  .menu-settings {
    background-image: url('../../assets/menu_settings_active.png');
  }

  .menu-surveys {
    background-image: url('../../assets/menu_surveys_active.png');
  }

  .menu-urgent-news {
    background-image: url('../../assets/menu_urgent_news_active.png');
  }

  .menu-users {
    background-image: url('../../assets/menu_users_active.png');
  }
}