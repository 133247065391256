.button {
  border: none;
  border-radius: 25px;
  box-shadow: 0px 3px 6px rgba(0,0,0, 0.16);

  font-weight: bold;

  cursor: pointer;

  &.circle {
    padding: 0;
    * {
      padding: 10px;
    }
  }

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    cursor: not-allowed;
  }

  * {
    padding: 15px 20px;
    display: block;
  }

  &.default {
    color: #407BFF !important;
    background-color: rgb(255, 255, 255);

    &:disabled {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  &.primary {
    color: white !important;
    background-color: rgb(64, 123, 255);
    
    &:disabled {
      background-color: rgba(64, 123, 255, 0.6);
    }
  }

  &.success {
    color: white !important;
    background-color: rgb(40, 167, 69);

    &:disabled {
      background-color: rgba(40, 167, 69, 0.6);
    }
  }

  &.info {
    color: white !important;
    background-color: rgb(91, 192, 222);

    &:disabled {
      background-color: rgba(91, 192, 222, 0.6);
    }
  }

  &.danger {
    color: white !important;
    background-color: rgb(220, 53, 69);

    &:disabled {
      background-color: rgba(220, 53, 69, 0.6);
    }
  }
}

.login-button {
  border-radius: 10px;
}

.tool-button {
  margin: 0 5px;
}