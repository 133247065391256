.list-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .body {
    flex-grow: 1;
  }
}


.list-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .list-header {
    padding: 10px;
    color: #888888;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // .search {
    //   display: flex;
    //   align-items: center;
    //   flex-grow: 1;
    //   margin-right: 10px;

    //   .clear {
    //     margin-left: -30px;
    //   }
    // }

    .filter {
      width: 200px;
    }
  }

  .list-body {
    height: 100%;
    flex-grow: 1;
    background-color: white;
  }
}

.list-column-layout {
  display: flex;
  height: 100%;

  .left {
    width: 35%;
  }

  .right {
    width: 65%;
    margin-left: 30px;
  }
}

.list-header-placeholder {
  height: 54px;
}

.list-image-cell {
  display: flex !important;
  align-items: center;
}