.item-set-container {
  display: flex;
  justify-content: space-between;

  &.vertical {
    flex-direction: column;
  }

  .half {
    width: 49%;
  }

  &>div:last-child {
    // margin-left: 30px;
  }

  .left {
    width: 65%;
  }
  .right {
    width: 35%;
    margin-left: 30px;


  }


  .right-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .right-action {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    .button {
      margin: 5px;
    }
  }
}
