.tabs-container {
  display: flex;
  height: 60px;

  .tab {
    margin: 0 15px;
    padding: 0 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    border-bottom: 4px solid white;

    flex-direction: column;

    .name {
      font-size: small;
    }

    .count {
      font-size: small;
    }

    &:hover, &.active {
      border-color: #407BFF;
    }

    &:hover {
      opacity: 0.6;
    }

    &.active {
      opacity: 1;
    }
  }
}