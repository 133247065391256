.flatpickr-calendar {
  .flatpickr-months .flatpickr-month, .flatpickr-weekdays, span.flatpickr-weekday {
    background-color: white;
  }
  
  .flatpickr-day.selected, .startRange, .endRange {
    background-color: #407BFF !important;
    border-color: #407BFF !important;
  }

  .inRange {
    background-color: #D6E3FF !important;
    box-shadow: -5px 0 0 #D6E3FF, 5px 0 0 #D6E3FF !important;
  }
}