@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif, 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #323232;
  background-color: #F5F5F5;
}

input, textarea {
  font-family: 'Open Sans', sans-serif, 'Arial';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-material.css';
.ag-theme-material .ag-icon-checkbox-checked {
  color: #407BFF;
}

@import '~react-toastify/dist/ReactToastify.css';
@import '~flatpickr/dist/themes/light.css';

@import "./styles/main";