.question-selector {
  display: flex;
  flex-direction: column;
  padding: 5px;

  .question-type {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    color: #888888;
    border-radius: 25px;
    box-shadow: 0px 3px 6px rgba(0,0,0, 0.16);
  }
}

.question-control {
  & > * {
    margin: 0 10px;
  }
}

.questions-tab {
  display: flex;

  .questions-left {
    width: 70%;
  }

  .questions-right {
    width: 30%;
    margin-left: 20px;
  }
}

.question-panel {

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .body {
    width: 100%;
    
  }
}

.option-wrapper {
  display: flex;
  align-items: center;
  
  padding: 10px 0;

  .option-input {
    flex-grow: 1;

    .border-bottom {
      margin: 0;
    }
  }
}