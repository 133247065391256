.simple-table {
  table {
    width: 100%;

    tr {

      th, td {
        padding: 5px;
  
        text-align: left;
      }
    }
  }

  &.right {
    th:last-child, td:last-child {
      text-align: right;
    }
  }

  &.stat {
    table {
      border-collapse: collapse;
    }

    th:not(:first-child), td:not(:first-child) {
      text-align: right;
    }

    th, td {
      border-bottom: 1px solid lightgrey;
      padding: 10px;
    }

    tr {
      &:hover {
        background-color: #f5f5f5;
      }
    }

    &.stat-has-total {
      tbody {
        tr:last-child {
          border-top: 2px solid lightgrey;
          font-weight: bold;
        }
      }
    }
  }
}