@mixin minimalistic-input-box($color) {  
  color: $color;
  
  border: 0;
  border-bottom: 1px solid $color;
  border-radius: unset;

  background-color: transparent;
  box-shadow: none;

}

@mixin minimalistic-input-box-no-border($color) {  
  color: $color;
  
  border: 0;
  border-radius: unset;

  background-color: transparent;
  box-shadow: none;

  margin: 0;
}



input, textarea, button {  
  &:focus {
    outline: 0;
    // box-shadow: none;
  }
}

input::-ms-clear {
  display: none;
}

textarea {
  resize: vertical;
}

.search-input {
  @include minimalistic-input-box(#888888);
  width: 100%;
  margin: 0 5px 0 5px;
  padding: 10px 0;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 10px;

  &.vertical {
    flex-direction: column;
    align-items: flex-start;

    .input-label {
      text-decoration: underline;
    }
  }

  .input-label {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    color: black;
  }

  &.group {
    .input-label {
      align-self: flex-start;
      padding-top: 10px;
    }
  }
}

.form-input {
  @include minimalistic-input-box-no-border(#323232);
  font-size: unset;
  width: 100%;
  padding: 10px 8px;

  &:disabled {
    color: grey;
    cursor: not-allowed;
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  padding: 5px;

  .name {
    margin-left: 30px;
  }
}

.checkbox-config {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;

  .config-name {
    width: 250px;
  }
}

.radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .name {
    margin-left: 30px;
  }
}

.checkbox-list-container {
  display: flex;
  flex-direction: column;

  .checkbox-label {
    display: flex;
    padding: 5px;

    .name {
      margin-left: 30px;
    }
  }
}

.radio-list-container {
  max-height: 250px;
  overflow-y: auto;
  margin-top: 5px;

  &.color-picker {
    display: flex;
    flex-wrap: wrap;

    .color-cell {
      margin: 5px;
      width: 50px;
      height: 50px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: black;
      border: 1px solid lightgrey;
    
      &.selected {
        border: 1px solid black;
      }
    }
  }
}

.html-input-container {
  // .wrapper {

  // }

  // .editor {
  //   height: 200px;

  //   .DraftEditor-root {
  //     height: auto;
  //   }
  // }

  // .toolbar {
  //   border: none;
  // }

  // .toolbar-icon {
  //   border: none;

  //   &:hover {
  //     box-shadow: none;
  //     opacity: 0.6;
  //   }
  // }
  
  width: 100%;
  
  .editor {
    .ql-container {
      font-size: 16px;
    }
    .ql-editor {
      height: 200px;
      word-break: break-all;
    }
    .ql-tooltip {
      z-index: 1;
    }
  }
}

.image-input-container {

  &.icon {
    display: flex;
    align-items: center;

    .image-display {
      margin-right: 10px;
    }

    .image-wrapper {
      width: 50px;
      height: 50px;
    }

    .remove-overlay {
      border: 1px solid lightgrey;
      border-radius: 10px;
    }
  }

}

.select-input-container {
  .select-input {

    .select-input__control {
      // cursor: pointer;
      // border: none;
    }
  }
}

.image-display {
  text-align: center;
  position: relative;

  &:hover {
    .remove-overlay {
      display: flex;
    }
  }

  .remove-overlay {
    display: none;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.file-input-container, .image-selector-container, .image-selector {
  .label-row {
    padding: 5px 0;
    span {
      margin-left: 5px;
    }
  }

  .files-wrapper {
    display: flex;
    flex-wrap: wrap;

    .file-row {
      display: flex;
      align-items: center;
    }

    .file {
      margin: 5px;
      padding: 8px 15px;
      display: flex;
      align-items: center;

      color: white;
      background-color: #407BFF;
      border-radius: 50px;

      .name {
        margin-right: 5px;
      }
    }

  }

  .image-display {
    width: auto;
    text-align: left;
    margin: 5px;

    .image-wrapper {
      width: auto;
      height: auto;
    }

    .image {
      max-width: 200px;
      max-height: 200px;
      width: auto;
      height: auto;
    }
  }

  .image-selection-wrapper {
    display: flex;
    flex-wrap: wrap;

    .image-selection {
      border: 3px solid transparent;

      &.selected {
        border: 3px solid #407BFF;
      }
    }

    .image-display {
      margin: 0;
      padding: 5px;
    }

    .image {
      max-width: 300px;
      max-height: 300px;
    }
  }
}

.target-audience-selector {

  .condition-selector {
    display: flex;
    height: 300px;

    .selector-wrapper {
      height: 100%;
      overflow-y: auto;
      .selector-content {
        padding: 15px;
      }
    }

    .group-wrapper {
      width: 30%;
      border-right: 1px solid lightgrey;

      .group {
        &.active {
          background-color: #F5F5F5;
        }
        
      }
    }
    .condition-wrapper {
      width: 70%;

      .condition {

        &.active {
          color: white;
          background-color: #407BFF;
        }
      }
    }
  }
}

.selected-conditions {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  padding: 10px;

  .condition {
    margin: 5px;
    padding: 8px 15px;
    display: flex;
    align-items: center;

    color: white;
    background-color: #407BFF;
    border-radius: 50px;

    .name {
      margin-right: 5px;
    }
  }
}

.multi-lang-input-container {
  display: flex;

  & > :not(:last-child) {
    border-right: 1px solid lightgrey;
  }

  .lang-input {
    width: 100%;
    padding: 0 10px;
  }
  .input-label {
    text-decoration: underline;
  }
}

.linkage-selector {

  .linkage-preview {
    // border: 1px solid lightgrey;
    // border-radius: 13px;
    padding: 10px;

    .linked-type {
      font-weight: bold;
      font-size: small;
    }
    .linked-name {
      font-weight: bold;
      font-size: larger;
      margin: 5px 0;
    }
    .linked-label {
      font-size: small;
    }
  }

  .linkage-select {
    width: 100%;
  }
}

.date-range-input-wrapper {
  min-width: 250px;
  width: 100%;

  display: flex;
  align-items: center;

  .flatpickr-input {
    text-align: right;
  }

  &.border {
    .flatpickr-input {
      border: 1px solid rgb(204, 204, 204);
      border-radius: 4px;
      padding: 7px 8px;
    }
  }

  &.text-align-left {
    .flatpickr-input {
      text-align: left;
    }
  }
}