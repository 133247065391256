.portal-container {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;


  .top {
    min-height: 50px;
    height: 50px;
    padding: 10px;

    background-color: white;

    .topnav {
      height: 100%;
    }
  }

  .bottom {
    flex-grow: 1;
    overflow-y: hidden;
    display: flex;

    .sidenav, .content {
      padding: 20px;
      overflow-y: auto;
    }

    .sidenav {
      border-right: 2px solid #E9E9E9;
      min-width: 50px;

      &.extend {
        min-width: 200px;
      }
    }
  
    .content {
      flex-grow: 1;
    }
  }


}