.login-container {
  width: 100vw;
  height: 100vh;

  display: flex;

  .login-left {
    width: 40%;

    .logo-wrapper {
      margin: 30px 30px 0 30px;
      padding: 20px;
      height: 100px;
      width: 100px;
      // border-radius: 50%;
      // background-color: white;
      box-shadow: none;

      .logo {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/logo.png');
        background-size: contain;
      }
    } 
    .description {
      font-size: small;
      padding: 0 50px;
    }
    .login-method {
      padding: 20px 50px;

      .button {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .login-right {
    width: 60%;

    .login-image {
      width: 100%;
      height: 100%;
      background-color: grey;
      background-image: url('../../assets/login_bg.png')
    }
  }
}