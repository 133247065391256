.topnav-container {
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;

    // * {
    //   padding: 0 5px;
    // }
  }

  .right {
    display: flex;
    align-items: center;

    // * {
    //   padding: 0 5px;
    // }
  }

  .logo-wrapper {
    height: 50px;
    width: 80px;
    
    margin-left: 20px;

    .logo {
      width: 100%;
      height: 100%;
      background-image: url('../../assets/logo.png');
      background-size: contain;
    }
  }

  .notification {
    
    .notice-button {
      height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .option {
    .option-down {
      margin-left: 10px;
    }
  }

  .user {
    display: flex;
    align-items: center;
    height: 40px;

    .user-icon-wrapper {
      width: 40px;
      height: 40px;
    }
    .name {
      margin-left: 5px;
    }
  }
}

.lang-select-modal {
  padding: 20px;

  .lang {
    text-align: center;
    padding: 20px 50px;
  }
}