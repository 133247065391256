


.post-dashboard-control {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .interval {
    width: 200px;
  }

  .range {
    width: 200px;

    input {
      font-size: 14px;
      color: #323232;
      width: 100%;
      text-align: right;

      &:hover {
        color: #407BFF;
      }
    }
  }
}

.post-dashboard-stat-title {
  margin: 30px;
  font-size: x-large;
  text-align: center;
}

.stat-control-row {
  display: flex;
  padding: 20px;
  .dropdown {
    width: 200px;
  }
}

.stat-cell-container {
  display: flex;
  .stat-cell {
    flex-grow: 1;
    padding: 20px;
    border: 1px solid #DDDDDD;
  }
}

.post-preview-body {
  padding: 10px;
  .phone-preview-container {
    display: flex;
    justify-content: center;

    .phone {
      width: 375px;
      margin: 10px;
      border: 1px solid lightgrey;
      min-height: 500px;

      .list-cell-container {
        width: 342px;
        box-shadow: 0px 3px 6px rgba(0,0,0, 0.16);
        border-radius: 13px;
      }

      .post-image {
        border-bottom: 1px solid lightgrey;

        .image-wrapper {
          height: 100%;

        }
      }
      .post-detail {
        padding: 10px 30px;
      }

      .category-row {
        display: flex;
        justify-content: space-between;
      }
      .category-name {
        color: #3D50E4;
        font-weight: bold;
      }
      .new-label {
        background-color: #FC4C6E;
        border-radius: 6px;
        color: white;
        padding: 2px 10px;
      }

      .title {
        font-size: 22px;
        font-weight: bold;
        padding: 3px 0;
      }

      .caption {
        padding: 3px 0;
        // white-space: pre;
      }

      .date {
        color: #6C6C6C;
        padding: 3px 0;
      }

      .days-left {
        color: #3D50E4;
        font-size: small;
        margin: 10px 0;

        display: flex;
        align-items: center;
        border-radius: 50px;
        background-color: rgb(222, 225, 247);

        width: min-content;

        img {
          height: 18px;
          width: 18px;
        }

        span {
          // padding: 5px 10px;
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        border-top: 1px solid #3D50E4;
        font-weight: bold;

        .left {
          display: flex;

          * {
            padding: 5px;
            display: flex;
            align-items: center;
          }
        }
        .right {
          * {
            padding: 5px;
            display: flex;
            align-items: center;
          }
        }
      }

      .attachment-container {

        .attachment {
          border-top: 1px solid lightgrey;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 7px 30px;
          margin: 0 -30px;
          // box-shadow: 0px 3px 6px rgba(0,0,0, 0.16);

          &:last-child {
            border-bottom: 1px solid lightgrey;
          }

          .name {
            // padding: 0 5px;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // overflow: hidden;
          }
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      align-items: center;

      .home {
        font-size: 20px;
        font-weight: bold;
        padding: 15px 24px;
        align-self: flex-start;
      }

      .post-image {
        height: 228px;

        .image-wrapper {

          .image {
            border-radius: 13px 13px 0 0;
          }
        }
      }

      .category-container {
        font-size: 17px;
        font-weight: bold;
        padding-left: 28px;
        padding-bottom: 22px;
        align-self: flex-start;

        .all {
          margin: 5px;
          border-bottom: 2px solid #3D50E4;
        }
      }

      .post-advertisement-container {
        width: 100%;
        margin-top: 10px;

        .post-image, .image {
          border: 0;
          border-radius: 0 !important;
        }

        .post-ad-detail {
          color: white;
          padding: 15px 30px;

          .post-ad-action-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: smaller;

            .post-ad-like {
              display: flex;
              align-items: center;

              img {
                padding-left: 5px;
              }
            }

            .post-ad-view-btn {
              background-color: white;
              color: black;
              border-radius: 20px;
              padding: 5px 10px;
            }
          }
        }
      }
    }

    .detail {

      .back {
        padding: 25px;
        border-bottom: 1px solid lightgrey;
      }

      .actions {
        padding: 10px 30px;
        border-color: lightgrey;
      }

      .post-detail {
        .content {
          word-break: normal;
        }
      }

      .post-image {
        height: 250px;
      }
    }

    .preview-addon {
      margin-top: 10px;

      .preview-addon-button {
        border-radius: 8px;
        background-color: #3D50E4;
        color: white;
        text-align: center;
        font-weight: bold;
        padding: 13px;

        &.expired {
          background-color: lightgrey;
        }
      }

      &.promotion {
        &.list-cell-container {
          width: 315px;

          .image-wrapper {

            .image {
              border-radius: 13px 13px 0 0;
            }
          }
        }

        .preview-addon-button {
          border-radius: 0 0 13px 13px;
        }
      }
    }
  }

  .image-preview-container {
    display: flex;
    flex-wrap: wrap;

    .image-wrapper {
      margin: 15px;
      border: 1px solid lightgrey;

      width: 375px;
      height: 250px;
    }
  }
}

.post-set-container {
  .post-button-input-wrapper {
    .label {
      padding: 10px;
      font-weight: bold;
    }
  }
}