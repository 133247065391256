.loading-overlay {
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;

  background-color: rgba(0, 0, 0, 0.5);

  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.open {
    display: flex;
  }
}