@import "./button";
@import "./card";
@import "./list";
@import "./tab";
@import "./input";
@import "./layout";
@import "./table";
@import "./calendar";
@import "./image";
@import "./dropdown";
@import "./width";

.clickable {
  cursor: pointer;
}

.unclickable {
  cursor: not-allowed;
}

a {
  text-decoration: none;
  color: inherit;
  display: block;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.border-bottom {
  border-bottom: 1px solid lightgrey;
  margin: 10px 0;
}

.hover-opacity {
  &:hover {
    opacity: 0.6;
  }
}

.tip {
  margin-top: 5px;
  font-size: small;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.hover-blue-text {
  &:hover {
    color: #407BFF
  }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0,0,0, 0.16);
}

.placeholder {
  color: #DDDDDD;
}

.sticky-top {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;
}

.red-text {
  color: red;
}

.vertical-separator {
  border-right: 1px solid lightgrey;
}

.sort-handle {
  cursor: move;
}

.notification-container {

  .notification-wrapper {
    padding: 10px 20px;

    border-bottom: 1px solid lightgrey;
  }

  .empty-placeholder {
    padding: 20px;
  }
}


.search {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 10px;

  .clear {
    margin-left: -30px;
  }
}

.text-underline {
  text-decoration: underline;
}

.center-info-msg {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 50px;
}

.text-center {
  text-align: center;
}

.text-header {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}