.dashboard-container {


  .user-info-card {

    .banner {
      &.image-wrapper {
        position: relative;
      }

      .image {
        background-image: url('../../../assets/dashboard_banner.png');
        background-size: cover;
      }

      .logo {
        position: absolute;
        right: 0;
        top: 0;

        margin: 5px 20px;
        width: 60px;
        height: 60px;
        background-image: url('../../../assets/logo_white.png');
        background-size: contain;
      }
    }

    .user-info {
      display: flex;
      align-items: center;

      padding: 20px 0;

      .user-icon-wrapper {
        height: 50px;
        width: 50px;

        margin: 0 20px;
      }

      .info {

        .name {
          font-size: large;
          font-weight: bold;
        }
        .desc {
          font-size: small;
        }
      }
    }
  }

  .reads {
    display: flex;
    justify-content: space-between;
    
    & > :first-child {
      width: 29%;
    }

    & > :not(:first-child) {
      width: 69%;
    }

    .today {
      .delta {
        color: #6AFFD1;
        display: flex;
        align-items: flex-end;
        padding-bottom: 10px;

        .percent {
          font-size: xx-large;
        }
        .percentage {
          font-size: large;
        }
        .arrow {
          justify-self: flex-end;
        }
      }

      .count {
        font-size: x-large;
      }

    }
  }
}

.range-interval-filter-container {
  display: flex;
  align-items: center;
  flex-grow: 1;

  .filter-wrapper {
    flex-grow: 1;
    width: 50%;

    .filter-label {
      font-weight: bold;
      text-decoration: underline;
      padding: 5px;
    }

    .filter-body {
      padding: 5px;

      .form-input {
        text-align: left;
      }
    }
  }
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  .button {
    margin: 0 5px;
  }
}

.user-stat-header {
  font-size: large;
  font-weight: bold;
  // text-decoration: underline;
  text-align: center;
  padding: 15px;
}